<template>
  <div class="quiz" v-if="isWebView">
    <!-- 상단 캐릭터 컴포넌트 -->
    <GameCharacter
        :isQuiz="true"
        :data="data"
        :name="name"/>
    <!-- // 상단 캐릭터 컴포넌트 -->
    <!-- 퀴즈 내용 -->
    <div class="section">
      <label class="label">퀴즈 내용</label>
      <div class="quizBox">
        <!-- 퀴즈 문제 -->
        <div class="title">
          <span v-html="data.quiz_subject"/>
        </div>
        <!-- // 퀴즈 문제 -->
        <!-- 퀴즈 이미지 -->
        <div class="img" v-if="data.quiz_image">
          <img :src="data.quiz_image" alt="">
        </div>
        <!-- // 퀴즈 이미지 -->
        <!-- 퀴즈 정답 항목 -->
        <div class="radio">
          <div class="checkbox radio" v-for="(data, i) in answerList" :key="i">
            <input
                type="radio"
                v-model="chkAnswer"
                :value="data.answer"
                :id="i">
            <label :for="i">{{data.answer}}</label>
          </div>
        </div>
        <!-- // 퀴즈 정답 항목 -->
      </div>
      <!-- 퀴즈 버튼 -->
      <div class="btnBox">
        <div class="goAnswer cursorPointer" @click="checkCorrectAnswer">정답제출</div>
        <div class="goHint cursorPointer" v-if="onHint" @click="checkHint">힌트보기</div>
      </div>
      <!-- // 퀴즈 버튼 -->
    </div>
    <!-- // 퀴즈 내용 -->
    <!-- 퀴즈 참가내역 -->
    <div class="section mt45">
      <label class="label">퀴즈 참가내역</label>
      <div class="historyBox" v-if="history.length !== 0">
        <div v-for="(data, idx) in history" :key="idx" class="historyList" :class="{ 'no-border': idx === history.length - 1 }">
          <div class="rightBox">
            <p class="title">{{ data.setDate }} {{ data.target_title }}</p>
            <p>{{ data.result_item }}</p>
          </div>
          <div class="leftBox">
            <img v-if="data.status === 'Y'" src="@/assets/img/etc/history_correct.png" alt="">
            <img v-else src="@/assets/img/etc/history_wrong.png" alt="">
          </div>
        </div>
      </div>
        <div v-else class="historyBox">
        <div class="historyList no-border">
          <p class="noHistory">퀴즈 참가 내역이 없습니다.</p>
        </div>
      </div>
    </div>
    <!-- // 퀴즈 참가내역 -->
    <!-- 퀴즈 안내사항 -->
    <div class="section mt45">
      <label class="label">퀴즈 안내사항</label>
      <div class="infoBox" v-html="quiz_info"/>
    </div>
    <!-- // 퀴즈 안내사항 -->
    <!-- 퀴즈 알럿 -->
    <GameAlert
        v-if="isOpen"
        :tmp="tmp"
        :hint="hint"
        @close="close"
        @offCooldown="offCooldown"
    />
    <!-- // 퀴즈 알럿  -->
  </div>
  <div style="height: 1000px;" v-else></div>
</template>
<script>

import GameCharacter from "@/components/Etc/GameCharacter.vue"
import GameAlert from "@/components/Etc/GameAlert.vue"
import http from "@/api/index"
import dayjs from "dayjs"

// ----- flutter event
let isAppReady = false;
window.addEventListener("flutterInAppWebViewPlatformReady", () => {
  isAppReady = true;
});
function toastMsg(msg) {
  if (isAppReady) {
    window.flutter_inappwebview.callHandler('toastMsg', msg )
  }
}
function externalLink(link) {
  if (isAppReady) {
    window.flutter_inappwebview.callHandler('externalLink', link )
  }
}
// ----- flutter event

export default {
  name: "QuizView",
  components: {
    GameAlert,
    GameCharacter
  },
  computed: {
    name() {
      return this.$store.state.user.name
    }
  },
  data() {
    return {
      isWebView : false, // 추후 false로 변경
      isOpen: false,
      tmp: {
        name: 'quiz',
        title: '',
        type: '',
        icon: '',
        desc: ''
      },
      data: {
        date: '',
        title: '',
        team: '',
        deadline: '',
        quiz_subject: '',
        quiz_image: ''
      },
      hint: {
        img: '',
        tmp: '',
        url: ''
      },
      onHint: false, // 힌트 버튼 활성화
      answerList: [], // 퀴즈 정답 항목
      chkAnswer: '', // 선택한 퀴즈 정답
      answer: '', // 정답
      history: [],
      quiz_info: '',
      userAgent: '',
      id: '',
      game_date: '',
      game_id: '',
      isCooldown: false,
    };
  },
  created() {
    this.userAgent = navigator.userAgent;
    if (this.userAgent.indexOf('JeonBukWebView') === -1) {
      alert('잘못된 접근입니다.')
      return window.location.href = '/'
    } else {
      this.chkChkIn()
    }
  },
  methods: {
    chkChkIn () {
      http.get('/app/mypage/check_in')
          .then(res => {
            if(res.data.success === false) {
              alert('체크인이 필요합니다. 전북현대 앱을 확인해주세요.')
              return window.history.back()
            }
            this.isWebView = true
            this.id = this.$route.query.id
            this.game_id = this.$route.query.game_id
            this.game_date = this.$route.query.game_date
            this.init()
            this.historyInit()
          })
          .catch(err =>{
            alert('잠시 후에 다시 시도해주세요')
            console.log('>>>>>> err', err);
          })
    },
    checkCorrectAnswer() {
      // 중복 방지
      if (this.isCooldown) {
        return
      }
      this.isCooldown = true
      const today = dayjs()
      if (today.isBefore(this.start_date)) {
        const msg = '이벤트 시작 전입니다.'
        this.isCooldown = false
        return toastMsg(msg)
      }
      if (today.isAfter(this.end_date)) {
        const msg = '종료된 이벤트입니다.'
        this.isCooldown = false
        return toastMsg(msg)
      }

      if (this.chkAnswer === '') {
        const msg = '정답을 먼저 입력해주세요'
        this.isCooldown = false
        return toastMsg(msg)
      }

      this.tmp.icon = '';
      this.tmp.type = 'answer'
      const data = {
        'idx' : this.id,
        'answer' : this.chkAnswer
      }
        this.tmp.title = '';
        this.tmp.type = 'answer'
        this.tmp.img = '';
        this.tmp.desc = '';
        http.post('/app/game/quiz', data)
            .then(res => {
              if (res.data.success === true) {
                if (res.data.data.isAnswer === true) {
                  this.tmp.title = '정답입니다!';
                  this.tmp.icon = 'quiz_correct';
                  this.tmp.desc = res.data.data.prize.prize_title;
                } else {
                  this.tmp.title = '오답입니다.';
                  this.tmp.icon = 'quiz_wrong';
                  this.tmp.desc = '다음 기회에 참여해 보세요!';
                }
                this.close()
                this.historyInit()
              }
              else {
                const msg = res.data.message;
                setTimeout(() => {
                  this.isCooldown = false;
                }, 1000)
                return toastMsg(msg);
              }
            })
            .catch(() => {
              this.isOpen = false
              this.isCooldown = false
              const msg = '잠시 후 시도해주세요.';
              return toastMsg(msg)
            })
    },
    checkHint() {
      const today = dayjs()
      if (today.isBefore(this.start_date)) {
        const msg = '이벤트 시작 전입니다.'
        return toastMsg(msg)
      }
      if (today.isAfter(this.end_date)) {
        const msg = '종료된 이벤트입니다.'
        return toastMsg(msg)
      }

      this.tmp.icon = '';
      this.tmp.type = 'hint'
        if (this.hint.url && !this.hint.tmp && !this.hint.img) {
          const link = this.hint.url;
          externalLink(link);
          return
        }
      if (this.hint.url) {
        this.tmp.url = this.hint.url
      }
        this.tmp.title = '힌트';
        this.tmp.img = this.hint.img;
        this.tmp.desc = this.hint.tmp;
        this.tmp.type = 'hint'
        return this.close()
    },
    init() {
      // api 호출
      const id = this.id
      http.get(`/app/game/quiz?idx=${id}`)
          .then((res) => {
            const data = res.data.data
            this.data.subTitle = data.target_subtitle;
            this.data.title = data.target_title;
            this.start_date = dayjs(data.start_date)
            this.end_date = dayjs(data.end_date)
            this.data.deadline = dayjs(data.end_date).format("YYYY.MM.DD HH:mm");
            if (data.quiz_subject) {
              this.data.quiz_subject = data.quiz_subject.replace(/\n/g, "<br>");
            }
            this.data.quiz_image = data.quiz_image
            if (data.quiz_info) {
              this.quiz_info = data.quiz_info.replace(/\n/g, "<br>");
            }
            this.answer = data.answer

            if (data.quiz_hint || data.hint_image || data.hint_url) {
              this.onHint = true;
              this.hint.tmp = data.quiz_hint;
              this.hint.img = data.hint_image;
              this.hint.url = data.hint_url;
            }

            const items = data.quiz_item.split(',');
            items.forEach((item) => {
              if (item) {
                this.answerList.push({ answer: item });
              }
            });
          })
          .catch(err => {
            console.log(err)
            alert('잠시 후에 다시 시도해주세요')
          })
    },
    historyInit() {
      http.get(`app/game/quiz/result?idx=${this.id}`)
          .then(res => {
            const history = res.data.data
            history.map(el => {
              el.setDate = dayjs(el.cdate).format('MM.DD')
            })
            this.history = history
          })
          .catch(err => console.error(err))
    },
    close() {
      this.isOpen = !this.isOpen
    },
    offCooldown () {
      this.isCooldown = false
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
